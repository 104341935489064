import React from "react"
import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import Reaptcha from "reaptcha"
import moment from "moment"
import "moment-timezone"

import {
  KadenProductRanges,
  KadenPartnerDetails,
  DayOptions,
  MonthOptions,
  YearOptions,
  StateOptions,
} from "../UserForm/Dropdowns"

import companies from "./data"

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentDateTime: new Date().toLocaleString(),
      submissionMessage: "",
      success: false,
      value: "selected",
      registerUnits: [
        {
          type: "",
          modelNumber: "",
          serialNumber: "",
        },
      ],
      numberOfProducts: 1,
      unitType: "noType",
    }
  }

  addUnit = (item) => {
    this.setState(
      (prevState) => ({
        registerUnits: [
          ...prevState.registerUnits,
          {
            type: "",
            modelNumber: "",
            serialNumber: "",
          },
        ],
      }),
      () => {
        let targetId = `unit-${item + 1}`
        const titleElement = document.getElementById(targetId)
        titleElement.scrollIntoView({
          behavior: "smooth",
          inline: "end",
        })
      }
    )
    this.setState({
      numberOfProducts: item + 1,
    })
  }

  removeUnit = (index) => {
    var array = [...this.state.registerUnits]
    array.splice(-1, 1)
    this.setState({
      registerUnits: array,
      numberOfProducts: array.length,
    })
  }

  hideList = (input, target, datalistId) => {
    var datalist = document.querySelector(target)
    if (input.value.length < 1) {
      datalist.id = ""
    } else {
      datalist.id = datalistId
    }
  }

  cleanUnitValues = (values, set) => {
    // Only Remove the ones we don't need in case they added and removed one with values
    // Cleaning the fields before it submits to micro service
    if (set !== 5) {
      let number = set + 1
      let pattern = new RegExp(
        `(Unit)([${number}-5])(Type|SerialNumber|ModelNumber)`
      )
      for (const [key, value] of Object.entries(values)) {
        if (pattern.test(key)) {
          values[key] = ""
        }
      }
    }
    return values
  }

  render() {
    let { registerUnits } = this.state

    const TextInput = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <React.Fragment>
          <label
            className="uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <input {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    const CheckboxInput = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <React.Fragment>
          <label>
            <input {...field} {...props} />
            <span dangerouslySetInnerHTML={{ __html: label }} />
            {required && <abbr title="required">*</abbr>}
          </label>
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    const Select = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <React.Fragment>
          <label
            className="uk-display-block uk-margin-small"
            htmlFor={props.id || props.name || props.value}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <select className="uk-select" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </React.Fragment>
      )
    }

    const formattedLocalDateTime = moment()
      .tz("Australia/Melbourne")
      .format("DD/MM/YYYY HH:mm:ss")

    const form = (
      <Formik
        initialValues={{
          FirstName: "",
          LastName: "",
          EmailAddress: "",
          MobileNumber: "",
          StreetAddress: "",
          Suburb: "",
          State: "",
          Postcode: "",
          CompanyName: "",
          CompanyMobileNumber: "",
          InstallationDay: "",
          InstallationMonth: "",
          InstallationYear: "",
          Unit1Type: "",
          Unit1ModelNumber: "",
          Unit1SerialNumber: "",
          Unit2Type: "",
          Unit2ModelNumber: "",
          Unit2SerialNumber: "",
          Unit3Type: "",
          Unit3ModelNumber: "",
          Unit3SerialNumber: "",
          Unit4Type: "",
          Unit4ModelNumber: "",
          Unit4SerialNumber: "",
          Unit5Type: "",
          Unit5ModelNumber: "",
          Unit5SerialNumber: "",
          OptIn: false,
          recaptcha: "",
          DateAdded: `${formattedLocalDateTime}`,
        }}
        validationSchema={Yup.object().shape({
          FirstName: Yup.string().required("Required"),
          LastName: Yup.string().required("Required"),
          EmailAddress: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          MobileNumber: Yup.string().matches(
            /^[0-9]{10}$/,
            "Must be exactly 10 digits e.g. 0432112112"
          ),
          StreetAddress: Yup.string().required("Required"),
          Suburb: Yup.string().required("Required"),
          State: Yup.string().required("Required"),
          Postcode: Yup.number()
            .typeError("This field must be a number only")
            .required("Required"),
          CompanyName: Yup.string()
            .oneOf(
              companies,
              "Invalid Company name - please ensure you select a match from the drop down. If you begin typing any relevant matches should appear."
            )
            .required("Required"),
          CompanyMobileNumber: Yup.string().matches(
            /^[0-9]{10}$/,
            "Must be exactly 10 digits e.g. 0432112112"
          ),
          InstallationDay: Yup.string().required("Required"),
          InstallationMonth: Yup.string().required("Required"),
          InstallationYear: Yup.string().required("Required"),
          numberOfProducts: Yup.string(),
          Unit1Type: Yup.string().when("numberOfProducts", {
            is: (val) =>
              this.state.numberOfProducts >= 1 == true &&
              this.state.unitType !== "noType",
            then: Yup.string().required("Required"),
          }),
          Unit1ModelNumber: Yup.string().required("Required"),
          Unit1SerialNumber: Yup.string().required("Required"),
          Unit2Type: Yup.string().when("numberOfProducts", {
            is: (val) =>
              this.state.numberOfProducts >= 2 == true &&
              this.state.unitType !== "noType",
            then: Yup.string().required("Required"),
          }),
          Unit2ModelNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 2 == true,
            then: Yup.string().required("Required"),
          }),
          Unit2SerialNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 2 == true,
            then: Yup.string().required("Required"),
          }),
          Unit3Type: Yup.string().when("numberOfProducts", {
            is: (val) =>
              this.state.numberOfProducts >= 3 == true &&
              this.state.unitType !== "noType",
            then: Yup.string().required("Required"),
          }),
          Unit3ModelNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 3 == true,
            then: Yup.string().required("Required"),
          }),
          Unit3SerialNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 3 == true,
            then: Yup.string().required("Required"),
          }),
          Unit4Type: Yup.string().when("numberOfProducts", {
            is: (val) =>
              this.state.numberOfProducts >= 4 == true &&
              this.state.unitType !== "noType",
            then: Yup.string().required("Required"),
          }),
          Unit4ModelNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 4 == true,
            then: Yup.string().required("Required"),
          }),
          Unit4SerialNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 4 == true,
            then: Yup.string().required("Required"),
          }),
          Unit5Type: Yup.string().when("numberOfProducts", {
            is: (val) =>
              this.state.numberOfProducts >= 5 == true &&
              this.state.unitType !== "noType",
            then: Yup.string().required("Required"),
          }),
          Unit5ModelNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 5 == true,
            then: Yup.string().required("Required"),
          }),
          Unit5SerialNumber: Yup.string().when("numberOfProducts", {
            is: (val) => this.state.numberOfProducts >= 5 == true,
            then: Yup.string().required("Required"),
          }),
          OptIn: Yup.bool().oneOf([true], "Required"),
          recaptcha: Yup.string().required("Required"),
        })}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          this.cleanUnitValues(values, this.state.numberOfProducts)
          this.setState({
            submissionMessage: null,
            success: false,
          })
          fetch(process.env.GATSBY_FETCH_URL_WARRANTY, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify(values),
          })
            .then((response) => {
              if (response.status === 200) {
                this.setState({
                  submissionMessage:
                    "Thank you. We've received your submission and will be in touch.",
                })
                actions.resetForm()
                this.setState({ success: true })
              } else {
                actions.setState({
                  submissionMessage:
                    "There was an error with your submission. Please refresh and try again.",
                })
              }
            })
            .catch((error) => {
              this.setState({
                submissionMessage:
                  "There was an error with the form submission. Please refresh this page and try again.",
              })
            })
            .finally(() => actions.setSubmitting(false))
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, status }) => (
          <div
            className={
              isSubmitting ? "form-container spinner" : "form-container"
            }
          >
            <Form className="uk-grid-small uk-grid-row-medium uk-grid-column-medium uk-grid warranty-form">
              <div className="uk-width-1-1@s uk-grid-margin">
                <h2>Your contact information</h2>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin uk-first-column">
                <TextInput
                  label="First Name"
                  name="FirstName"
                  id="firstName"
                  type="text"
                  placeholder="First name"
                  className="uk-input"
                  required="true"
                />
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Last Name"
                  name="LastName"
                  id="lastName"
                  type="text"
                  placeholder="Last name"
                  className="uk-input"
                  required="true"
                />
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Email"
                  name="EmailAddress"
                  id="emailAddress"
                  type="email"
                  placeholder="jane@example.com"
                  className="uk-input"
                  required="true"
                />
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Mobile Number"
                  name="MobileNumber"
                  id="MobileNumber"
                  type="text"
                  placeholder="0412345678"
                  className="uk-input"
                  maxLength="10"
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin uk-margin-large-top">
                <h2>Installation Address</h2>
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Street Address"
                  name="StreetAddress"
                  id="streetAddress"
                  type="text"
                  placeholder="10 Collins Street"
                  className="uk-input"
                  required="true"
                />
              </div>
              <div className="uk-width-1-3@s uk-grid-margin">
                <TextInput
                  label="Suburb"
                  name="Suburb"
                  id="suburb"
                  type="text"
                  placeholder="Melbourne"
                  className="uk-input"
                  required="true"
                />
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 uk-grid-margin">
                <Select label="State" name="State" id="state" required="true">
                  <StateOptions />
                </Select>
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 uk-grid-margin">
                <TextInput
                  label="Post Code"
                  name="Postcode"
                  id="postcode"
                  type="text"
                  placeholder="3000"
                  className="uk-input"
                  required="true"
                  maxLength="4"
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin uk-margin-large-top">
                <h2>Installer Details</h2>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Company Name"
                  name="CompanyName"
                  id="companyName"
                  list="companies"
                  placeholder="Select the Company name"
                  className="uk-input"
                  autoComplete="off"
                  required="true"
                  onKeyUp={(e) =>
                    this.hideList(e.target, "datalist", "companies")
                  }
                  onFocus={(e) =>
                    this.hideList(e.target, "datalist", "companies")
                  }
                />
                <datalist id="companies">
                  <KadenPartnerDetails />
                </datalist>
              </div>
              <div className="uk-width-1-2@s uk-grid-margin">
                <TextInput
                  label="Mobile Number"
                  name="CompanyMobileNumber"
                  id="companyMobileNumber"
                  type="text"
                  placeholder="0412345678"
                  className="uk-input"
                  maxLength="10"
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin uk-margin-large-top">
                <h2>Product Information</h2>
              </div>
              <div className="uk-width-1-2@s uk-width-1-3@m uk-grid-margin">
                <fieldset>
                  <legend>
                    Installation Date <abbr title="required">*</abbr>
                  </legend>
                  <div className="uk-margin uk-grid-small uk-child-width-1-3 uk-grid">
                    <div>
                      <Select
                        name="InstallationDay"
                        id="installationDay"
                        label="Day"
                        required="true"
                      >
                        <DayOptions />
                      </Select>
                    </div>
                    <div>
                      <Select
                        name="InstallationMonth"
                        id="installationMonth"
                        label="Month"
                        required="true"
                      >
                        <MonthOptions />
                      </Select>
                    </div>
                    <div>
                      <Select
                        name="InstallationYear"
                        id="installationYear"
                        label="Year"
                        required="true"
                      >
                        <YearOptions />
                      </Select>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="uk-hidden uk-width-1-1@s uk-grid-margin">
                <Select
                  label="Number of Products (Not required to fill)"
                  name="numberOfProducts"
                  value={this.state.numberOfProducts}
                  key="numberOfProducts"
                  className="uk-hidden"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Select>
              </div>
              {registerUnits.map((val, idx) => {
                const { options, value } = this.state
                const units = [
                  "KL16",
                  "KL22",
                  "KL25",
                  "KL28",
                  "KC17",
                  "KC21",
                  "KC27",
                  "KC32",
                  "KUN315",
                  "KUN320",
                  "KUN325",
                  "KUN330",
                  "KUN415",
                  "KUN415",
                  "KUN420",
                  "KUN425",
                  "KUN430",
                  "KUN521",
                  "KUN530",
                  "KEX315",
                  "KEX320",
                  "KEX328",
                  "KEX415",
                  "KEX420",
                  "KEX428",
                  "KW09",
                  "KW12",
                  "KW18",
                  "KW21",
                ]

                const unitId = `unit${idx + 1}ModelNumber`
                const selectedValue = document.getElementById(unitId)

                if (!selectedValue && selectedValue === null) {
                  this.state.value = "selected"
                } else {
                  this.state.value = selectedValue.value
                }
                const selectedUnit = units.find(
                  (item) => item == this.state.value
                )
                return (
                  <React.Fragment key={idx}>
                    <div
                      className="uk-width-1-1@s uk-grid-margin"
                      id={`unit-${idx + 1}`}
                    >
                      <h3>
                        {`Unit ${idx + 1}`} <abbr title="required">*</abbr>
                      </h3>
                    </div>
                    <div className="uk-width-1-1@s uk-grid-margin">
                      <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-gap-2">
                        {!selectedUnit && (
                          <div className="uk-margin-right">
                            <CheckboxInput
                              label="Indoor"
                              name={`Unit${idx + 1}Type`}
                              id={`indoor${idx + 1}`}
                              value="Indoor"
                              className="uk-radio"
                              type="radio"
                            />
                          </div>
                        )}
                        {!selectedUnit && (
                          <div className="uk-margin-right">
                            <CheckboxInput
                              label="Outdoor"
                              name={`Unit${idx + 1}Type`}
                              id={`outdoor${idx + 1}`}
                              value="Outdoor"
                              className="uk-radio"
                              type="radio"
                            />
                          </div>
                        )}
                        <div className="uk-hidden">
                          <CheckboxInput
                            label="noType"
                            name={`Unit${idx + 1}Type`}
                            id={`noType${idx + 1}`}
                            value="noType"
                            className="uk-radio"
                            type="radio"
                            checked={!!selectedUnit ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-2@s uk-grid-margin">
                      <Select
                        name={`Unit${idx + 1}ModelNumber`}
                        id={`unit${idx + 1}ModelNumber`}
                        label="Model Number"
                        required="true"
                      >
                        <KadenProductRanges />
                      </Select>
                    </div>
                    <div className="uk-width-1-2@s uk-grid-margin">
                      <TextInput
                        name={`Unit${idx + 1}SerialNumber`}
                        id={`unit${idx + 1}SerialNumber`}
                        label="Serial Number"
                        type="text"
                        placeholder="E.g. 340918616019B070130020 or 1265586"
                        className="uk-input"
                        required="true"
                      />
                    </div>
                    <div className="uk-width-1-1@s uk-grid-margin">
                      <hr className="uk-divider-icon" />
                    </div>
                  </React.Fragment>
                )
              })}

              <div className="uk-width-1-1@s uk-grid-margin custom-button-default">
                {registerUnits.length == 5 && (
                  <p>
                    <small>
                      <span uk-icon="icon: info"></span> Maximum of 5 units can
                      be added
                    </small>
                  </p>
                )}
                {registerUnits.length !== 1 && (
                  <button
                    className="uk-button uk-button-default uk-width-1-1 button-remove"
                    onClick={this.removeUnit}
                    type="button"
                  >
                    Remove Last Product
                  </button>
                )}
                {registerUnits.length < 5 && (
                  <button
                    className="uk-margin-small-top uk-padding-small	uk-button uk-button-default uk-width-1-1"
                    onClick={(e) => this.addUnit(registerUnits.length)}
                    type="button"
                  >
                    <span uk-icon="icon: plus-circle; ratio: 1.4"></span>{" "}
                    Register Another Product
                  </button>
                )}
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <div className="uk-margin uk-grid-small uk-child-width-1-1@s uk-grid">
                  <div>
                    <CheckboxInput
                      label="I'd like to sign up to receive warranty information, product information, news, updates, tips and other communications from Kaden and the Reece Group. <br/>I have read the <a href='/privacy-policy' target='_blank' rel='help'>Privacy Policy</a>."
                      type="checkbox"
                      name="OptIn"
                      id="optin"
                      className="uk-checkbox"
                    />
                  </div>
                </div>
              </div>
              <div className="uk-width-1-3@s uk-grid-margin">
                <label
                  className="uk-text-bold uk-display-block uk-margin-small"
                  htmlFor="recaptcha"
                >
                  Verification
                </label>
                <Reaptcha
                  sitekey="6LdcWsYUAAAAAK4RDlHR3k8m2hqmU1bENl1GEV_E"
                  theme="light"
                  onVerify={(token) => {
                    setFieldValue("recaptcha", token)
                  }}
                />
                {errors.recaptcha && touched.recaptcha && (
                  <div className="uk-alert uk-alert-danger uk-margin-remove-top">
                    {errors.recaptcha}
                  </div>
                )}
              </div>
              <div className="uk-hidden uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Date submitted (Not required to complete - automated)"
                  name="DateAdded"
                  id="DateAdded"
                  type="hidden"
                  className="uk-input"
                  value={formattedLocalDateTime}
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-border-pill uk-width-medium"
                  disabled={isSubmitting}
                >
                  Register
                </button>

                <span className="uk-margin-left custom-error">
                  {Object.keys(errors).length ? (
                    <div className="uk-alert-danger uk-margin-top">
                      <span
                        uk-icon="warning"
                        className="uk-padding-small"
                      ></span>{" "}
                      Please fill out the required fields
                    </div>
                  ) : null}
                  {this.state.submissionMessage}
                </span>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )
    return form
  }
}

export default UserForm
